import { isDefined } from "@clipboard-health/util-ts";
import { IonCardSubtitle } from "@ionic/react";
import { LicenseTag } from "@src/app/licenseManager/components/licenseTag";
import { useWorkerQualifications } from "@src/app/licenseManager/hooks/useWorkerQualifications";
import { checkForUrgentShift } from "@src/app/openShifts/urgentShifts/utils";
import { useDefinedAgent } from "@src/app/store/helperHooks";
import { formatShiftDurationByDifference, getNonIPAmount } from "@src/app/utils/shiftHelper";
import { useCbhFlags } from "@src/appV2/FeatureFlags";
import { ShiftStateData } from "@src/appV2/Shifts/Shift/ShiftState/types";
import { InstantPayShiftDetails, Shift } from "@src/lib/interface";
import { FULL_ZONE_NAMES, getOffsetFormattedTextBetweenTimezones } from "@src/lib/utils";
import moment from "moment-timezone";
import { Fragment, useMemo } from "react";

import { PaymentDisplay } from "./types";
import { doesShiftIncludeLunchBreak } from "../../utils/shiftHelper";
import { getShiftTitleString } from "../helper";
import { formatRate } from "../helper";

interface ShiftTitleProps {
  shift: Shift;
  shiftStateData?: ShiftStateData;
  showPaymentAs: PaymentDisplay;
  hasMissingDocs?: boolean;
  showMissingDocs?: boolean;
  // we hide the pay when the shift it canceled
  // because a new shift to replace the old one
  // may be in the marketplace at a different rate
  hidePayCanceledShift?: boolean;
}
export function ShiftTitle(props: ShiftTitleProps) {
  const {
    shift,
    shiftStateData,
    showPaymentAs,
    hasMissingDocs = false,
    showMissingDocs = false,
    hidePayCanceledShift = false,
  } = props;
  /**
   * FIXME! moment.fn.zoneName is being redefined here
   * This mutates a global import, which means this can silently affect other callers with
   * no clear visibility about where this functionality came from.
   * Note that this would be changed at runtime, and only when this component renders.
   * It is redefined on each iteration.
   */
  moment.fn.zoneName = function () {
    const abbr = this.zoneAbbr();
    return FULL_ZONE_NAMES[abbr] || abbr;
  };
  const ldFlags = useCbhFlags();
  const agent = useDefinedAgent();

  const workerQualifications = useWorkerQualifications();
  const shiftDetails = useMemo(() => {
    const { tmz: facilityTimezone } = shift.facility ?? {};
    if (!isDefined(facilityTimezone) || facilityTimezone === "") {
      /**
       * moment-timezone returns undefined if given an empty string or undefined timezone.
       * In node
       * ```
       * moment = require("moment-timezone")
       * > moment("2023-10-11T14:00:00Z").tz("")
       * undefined
       * > moment("2023-10-11T14:00:00Z").tz()
       * undefined
       * ```
       */
      return {};
    }
    const formattedDuration = formatShiftDurationByDifference(
      shift.start!,
      shift.end!,
      facilityTimezone
    );
    const shiftStart = moment(shift.start).tz(facilityTimezone);
    const shiftEnd = moment(shift.end).tz(facilityTimezone);
    const formattedDate = shiftStart.format("dddd, MMMM DD");
    const nonLunchDuration = moment.duration(shiftEnd.diff(shiftStart)).asHours();
    const duration = doesShiftIncludeLunchBreak(shift.start, shift.end, nonLunchDuration)
      ? nonLunchDuration - 0.5
      : nonLunchDuration;
    const { isInstantPay } = shift;

    const { tmz: agentTimezone } = agent;
    let timezoneInfoText = "";
    if (
      agentTimezone &&
      moment(shift.start).tz(agentTimezone).utcOffset() !==
        moment(shift.start).tz(facilityTimezone).utcOffset()
    ) {
      timezoneInfoText = `${moment()
        .tz(facilityTimezone)
        .zoneName()} (${getOffsetFormattedTextBetweenTimezones(
        shift.start as string,
        agentTimezone as string,
        facilityTimezone as string
      )})`;
    }

    return {
      shiftStart,
      isInstantPay,
      duration,
      formattedDate,
      formattedDuration,
      timezoneInfoText,
    };
  }, [shift, agent]);
  const shouldShowShiftQualification = workerQualifications.length > 1;
  // TODO refactor this and payment status into components
  const shiftTitle = useMemo(() => {
    const title = getShiftTitleString({
      shiftStart: shiftDetails.shiftStart,
      shift,
      shiftStateData,
    });
    if (title) {
      const shiftTag = <span className="shift-title shift-title-color">{title}</span>;
      const shiftDateTag = (
        <span style={{ fontSize: "18px" }} className="">
          {shiftDetails.formattedDate}
        </span>
      );
      return moment().isAfter(shiftDetails.shiftStart) ? (
        shiftTag
      ) : (
        <span>
          {shiftTag} {shiftDateTag}
        </span>
      );
    }

    return <span className="shift-title">{shiftDetails.formattedDate}</span>;
  }, [shift, shiftDetails.formattedDate, shiftDetails.shiftStart, shiftStateData]);

  const instantPayDetails = shiftDetails.isInstantPay
    ? shift.instantPayDetails
    : ({} as InstantPayShiftDetails);

  const instantPayPartialAmount = instantPayDetails ? instantPayDetails.totalAmount : 0;

  const isShiftOngoing = shift && moment().isAfter(shift.start);

  const { instantPayAmountText, nonInstantPayAmountText } = useMemo(() => {
    if (showPaymentAs === "hourly") {
      return {
        instantPayAmountText: `$${formatRate(shift.finalPay)} per hour`,
        nonInstantPayAmountText: `$${formatRate(shift.finalPay)} per hour`,
      };
    } else if (showPaymentAs === "total") {
      return {
        instantPayAmountText: `$${formatRate(shift.instantPayDetails?.totalAmount ?? 0)} total${
          checkForUrgentShift(shift) ? ` earnings` : ""
        }`,
        nonInstantPayAmountText: `$${formatRate(getNonIPAmount(shift, ldFlags))} total${
          checkForUrgentShift(shift) ? ` earnings` : ""
        }`,
      };
    }
    return {};
  }, [showPaymentAs, shift, ldFlags]);

  return (
    <div>
      <IonCardSubtitle className="no-text-transform">{shiftTitle}</IonCardSubtitle>
      <IonCardSubtitle className="title no-text-transform">
        {shiftDetails.formattedDuration}
      </IonCardSubtitle>
      {shiftDetails.timezoneInfoText && shiftDetails.timezoneInfoText.length && (
        <IonCardSubtitle
          className="title no-text-transform"
          style={{
            background: "#D88C33",
            borderRadius: "4px",
            width: "fit-content",
            padding: "1px 4px",
            color: "#FFFFFF",
            fontWeight: 600,
          }}
        >
          {shiftDetails.timezoneInfoText}
        </IonCardSubtitle>
      )}
      {!hidePayCanceledShift && (
        <>
          {shiftDetails.isInstantPay ? (
            <IonCardSubtitle className="title no-text-transform">
              <span className="payment-text-color">{instantPayAmountText}</span> |{" "}
              <span className="payment-text-color-instantpay">
                {!shift.instantPayDetails?.is100InstantPayEnabled ? (
                  <>${formatRate(instantPayPartialAmount)} </>
                ) : null}
                InstantPay
              </span>
            </IonCardSubtitle>
          ) : (
            <IonCardSubtitle className="title no-text-transform">
              <span className="payment-text-color">{nonInstantPayAmountText}</span>
            </IonCardSubtitle>
          )}
        </>
      )}
      {showMissingDocs && hasMissingDocs && !isShiftOngoing && (
        <IonCardSubtitle className="title no-text-transform">
          <span className="missing-docs-color">Missing Documents</span>
        </IonCardSubtitle>
      )}
      {shouldShowShiftQualification && <LicenseTag agentReq={shift.agentReq ?? ""}></LicenseTag>}
    </div>
  );
}
